import React, {useContext} from "react";
import IcoBag from '../../../../static/images/icons/bag.inline.svg';
import {CartController} from "../../../../helpers/CartController";
import { CartContext } from '../../../../context/CartContext';
import { ProductCardEn } from '@components/pages/shop';

export const StoreHeaderEn = ({filterByCategory, allWpProductCategory, orderBy, prod}) => {

  const {cartCountEn, setShowShoppingCartEn} = useContext(CartContext);

  return (
    <div className="container">
      <div className="header">
        <h1>Store</h1>
        <button
          type="button"
          onClick={() => setShowShoppingCartEn((prev) => !prev)}>
          <span className="ico-box">
            <IcoBag />
            {cartCountEn !== 0 ? (<span className="quantity">{cartCountEn}</span>) : (<></>)}
          </span>
        </button>
      </div>

      <div className="filter">
        <select className="form-select" defaultValue=""  onChange={filterByCategory} required>
          <option value="**">
            All products
          </option>
          {allWpProductCategory.nodes.map((v, index) => {
            if(v.name === 'Uncategorized') return ;
            return (
            <option value={v.slug} key={index}>
              {v.name}
            </option>
          )})}
        </select>

        <select className="form-select" defaultValue="" onChange={orderBy} required>
          <option value="5">Order by</option>
          <option value="3">Most cheaper</option>
          <option value="2">Most expensive</option>
          <option value="4">A - Z</option>
          <option value="5">Z - A</option>
        </select>
      </div>

      <div className="product-box">
        {prod.map((v, index) => (
          <ProductCardEn data={v} index={v.sku} key={v.sku}/>
        ))}
      </div>
    </div>  
  );
};
export default StoreHeaderEn;