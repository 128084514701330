import React, {useContext, useState, useEffect} from 'react';
import LayoutStore from '../layouts/store/_layout.en.jsx';
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";
// import { StoreHeaderEn } from '@components/pages/shop/StoreHeader/';
import StoreHeaderEn from '@components/pages/shop/StoreHeader/StoreHeader.en';
import { CartContext, CartProvider } from '../context/CartContext';

const StorePageEn = () => {
  const { wpPage, allWpProduct, allWpProductCategory } = useStaticQuery(graphql`
    query StoreEnglish {
      allWpProductCategory (filter: {link: {regex: "/^(\/en\/|en\/)/i"}}) {
        nodes {
          id
          name
          termGroupId
          termTaxonomyId
          uri
          link
          databaseId
          count
          slug
          seo {
            canonical
          }
        }
      }
      allWpProduct (filter: {link: {regex: "/^(\/en\/|en\/)/i"}}) {
        nodes {
          image {
            link
            sourceUrl
          }
          sku
          slug
          status
          totalSales
          shortDescription
          productCategories{
            nodes{
              slug
            }
          }
          ... on WpSimpleProduct {
            id
            price
            databaseId
            name
            stockStatus
            stockQuantity
          }
          name
          featured
          galleryImages {
            nodes {
              sourceUrl
              altText
            }
          }
        }
      }
      wpPage(slug: {eq: "shop-2"}) {
        id
        date
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
        language {
          slug
          name
          locale
          id
          homeUrl
          code
        }
        seo {
          canonical
          metaDesc
          opengraphDescription
          opengraphImage {
            altText
            link
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphSiteName
          opengraphPublisher
          opengraphTitle
          opengraphType
          opengraphUrl
          schema {
            raw
          }
          title
          twitterDescription
          twitterImage {
            altText
            link
          }
          twitterTitle
        }
        slug
        status
      }
    }
  `);
  
  const [ prod, setProducts ] = useState(allWpProduct.nodes);
  const [order, setOrder] = useState('6');
  
  let products = allWpProduct.nodes;
  const filterByCategory = ({target}) => {
    products = [];
    if(target.value !== '**'){
      products = allWpProduct.nodes.filter(el => el.productCategories.nodes.map(el => el.slug).includes(target.value));
    }else{
      products = allWpProduct.nodes;
    }
    setProducts(products);
  };

  useEffect(()=>{
    let products = [];
    switch (order){
      case "6":
        products = allWpProduct.nodes;
        break;
      case "1":
        products = allWpProduct.nodes.sort((a,b) => {
          if(a.totalSales < b.totalSales) return 1;
          if(a.totalSales > b.totalSales) return -1;
        });
        break;
      case "2":
        products = allWpProduct.nodes.sort((a,b) => {
          const aPrice = parseFloat(a.price.replace('S/&nbsp;', '').replace(',', ''));
          const bPrice = parseFloat(b.price.replace('S/&nbsp;', '').replace(',', ''));
          if(aPrice < bPrice) return 1;
          if(aPrice > bPrice) return -1;
        });
        break;
      case "3":
        products = allWpProduct.nodes.sort((a,b) => {
          const aPrice =  parseFloat(a.price.replace('S/&nbsp;', '').replace(',', ''));
          const bPrice = parseFloat(b.price.replace('S/&nbsp;', '').replace(',', ''));
          if(aPrice > bPrice) return 1;
          if(aPrice < bPrice) return -1;
        });
        break;
      case "4":
        products = allWpProduct.nodes.sort((a,b) => {
          if(a.name > b.name) return 1;
          if(a.name < b.name) return -1;
        });
        break;
      case "5":
        products = allWpProduct.nodes.sort((a,b) => {
          if(a.name > b.name) return -1;
          if(a.name < b.name) return 1;
        });
        break;
      default:
        console.log('nothing match');
    }
    setProducts(products);
  },[order]);

  const orderBy = ({target}) => {
    setProducts([]);
    setOrder(target.value);
  };
  
  return (
    <>
      <CartProvider>
        <Seo 
          author={false}
          seo={wpPage.seo}
          pathname="/en/store"
          lang={wpPage.language.locale}
          thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}/>
        <LayoutStore pageId="store">
          <StoreHeaderEn 
            prod={prod} 
            orderBy={orderBy}
            filterByCategory={filterByCategory} 
            allWpProductCategory={allWpProductCategory}/>
        </LayoutStore>
      </CartProvider>
    </>
  );
};

export default StorePageEn;